import { EventEmitter, Injectable } from '@angular/core';
import { DataService } from './data.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class YoutubeService {
  youtubeKeylist: any;
  youtubeEmit: EventEmitter<boolean> = new EventEmitter();
  youtubeList: any;
  videoYtKey: string;

  constructor(private dataService: DataService) {
    if (environment.youtubeList && environment.googlekey) {
      this.videoYtKey = environment.googlekey;
      this.youtubeKeylist = environment.youtubeList;
      this.getYTList();
    }
  }

  public getYTList() {
    if (!this.youtubeList) {
      const url =
        'https://www.googleapis.com/youtube/v3/playlistItems?playlistId=' +
        this.youtubeKeylist +
        '&part=snippet&key=' +
        this.videoYtKey;
      this.dataService.getExternalData(url).subscribe(
        ytlist => {
          this.youtubeList = ytlist.items;
          this.youtubeEmit.emit(this.youtubeList);
          // console.log('Video list', this.youtube_list);
        },
        error => {
          console.log(error);
        },
      );
    }
  }

  public getSavedYTList() {
    return this.youtubeList;
  }
}
