import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';

import { SbmnComponent } from './sbmn.component';
import { SbmnRoutingModule } from './sbmn-routing.module';
import { ErrorDialogModule, ErrorInterceptor, LanguageModule } from './shared';
import { Angulartics2Module } from 'angulartics2';
//import {SocketIoModule} from 'ngx-socket-io';
import { StorageModule } from '@ngx-pwa/local-storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

Date.prototype.toJSON = function () {
  var today = new Date(this);
  today.setHours(today.getHours() + today.getTimezoneOffset() / -60);
  return today.toISOString();
};

@NgModule({
  declarations: [SbmnComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SbmnRoutingModule,
    LanguageModule,
    ErrorDialogModule,
    Angulartics2Module.forRoot(),
    //SocketIoModule.forRoot({ url: environment.chat.socket, options: {} }),
    StorageModule.forRoot({ IDBDBName: environment.brand }),
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatIconModule,
    MatDatepickerModule,
    MatMomentDateModule,
  ],
  providers: [
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [SbmnComponent],
})
export class SbmnModule {}
