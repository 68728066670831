import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorDialogComponent } from './error-dialog.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ErrorDialogComponent],
  exports: [ErrorDialogComponent],
})
export class ErrorDialogModule {}
