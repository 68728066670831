import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { GaEvent } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class GAService {
  constructor(private angulartics2: Angulartics2) {}

  public emision(b: any) {
    this.angulartics2.eventTrack.next({
      action: 'Emisión',
      properties: { event: 'Emitir', label: b.EstadoReserva, value: b.ImporteTotal },
    });
  }

  public customEvent(e: GaEvent) {
    this.angulartics2.eventTrack.next({
      action: e.action,
      properties: { event: e.event, label: e.label, value: e.value },
    });
  }
}
