///////////////////////////////////////////////////////////// PRE BOOMERANG
import packageJson from '../../../../package.json';

export const environment = {
  brand: 'navisk',
  production: false,
  domain: 'https://' + window.location.hostname,
  domainStatics: 'https://' + window.location.hostname.split('.')[0] + '.navisk.com',
  apidomain: 'https://' + window.location.hostname.split('.')[0] + '.navisk.com/api/',
  googlekey: null,
  youtubeList: null,
  recaptchakey: '6LcKYOUUAAAAAPiqu-Zke2vovQSYBztEKTb6qXi9',
  googleSiteVerification: '7thXmbmaTL41JjfSUh-KtDjagJzZSTBsNI5Bw8xEKkk',
  chat: {
    api: null,
    socket: null,
  },
  defaultLang: 'es',
  myMessages: true,
  allowedVideo: false,
  allowedManagerUsers: true,
  version: packageJson.version,
};

console.log(environment.apidomain);
