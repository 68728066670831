import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services';

@Injectable()
export class AuthGuard {
  constructor(private router: Router) {}

  canActivate() {
    if (AuthService.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
