import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';

const SbmnRoutes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'admin-tools',
        loadChildren: () =>
          import('projects/sources/src/app/protected/common/admin-tools/admin-tools.module').then(
            m => m.AdminToolsModule,
          ),
      },
      {
        path: 'agency/:agency/:code',
        loadChildren: () =>
          import('projects/sources/src/app/access/auto-login/auto-login.module').then(
            m => m.AutoLoginModule,
          ),
      },
      {
        loadChildren: () => import('projects/sources/src/app/health-check/health-check.module').then(m => m.HealthCheckModule),
        path: 'health'
      },
      {
        path: 'public-info/:content',
        loadChildren: () =>
          import('projects/sources/src/app/cms/info/info.module').then(m => m.InfoModule),
      },
      {
        path: 'tpv/:place/:type/:okko',
        loadChildren: () =>
          import(
            'projects/sources/src/app/protected/common/tpv/tpv-response/tpv-response.module'
          ).then(m => m.TpvResponseModule),
      },
      {
        path: environment.brand,
        loadChildren: () =>
          import('projects/sources/src/app/protected/protected.module').then(
            m => m.ProtectedModule,
          ),
      },
      {
        path: ':access',
        loadChildren: () =>
          import('projects/sources/src/app/access/access.module').then(m => m.AccessModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(SbmnRoutes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  providers: [],
  exports: [RouterModule],
})
export class SbmnRoutingModule {}
