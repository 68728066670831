import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { sha1 } from './sha1.service';
import { SocketService } from './socket.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private dataService: DataService,
    private httpClient: HttpClient,
    private socketService: SocketService,
    private storage: StorageMap,
  ) {
    this.domain = environment.apidomain;
  }

  private readonly domain: string;

  static buildHeader() {
    return new HttpHeaders()
      .set('Accept', 'application/json')
      .append('Content-Type', 'application/json;charset=UTF-8');
  }

  static getUser() {
    const agency = sessionStorage.getItem('[#@]' + environment.brand + '_user');
    return agency ? JSON.parse(agency) : null;
  }

  static getAdmins() {
    const chatAdm = sessionStorage.getItem('[#@]' + environment.brand + '_chat');
    return chatAdm ? JSON.parse(chatAdm) : null;
  }

  static isAuthenticated(): boolean {
    return sessionStorage.getItem('[#@]' + environment.brand + '_user') !== null;
  }

  static setSessionLimits() {
    const u = AuthService.getUser();
    const limit = u.TokenExpiraMinutos;
    const sessionLogin = new Date().getTime();
    const sessionLimit = (sessionLogin + limit * 60 * 1000).toString();
    sessionStorage.setItem('[#@]' + environment.brand + '_sessionLimit', sessionLimit);
  }

  static setToken(tk) {
    const user = AuthService.getUser();
    if (user) {
      user.Token = tk;
      sessionStorage.setItem('[#@]' + environment.brand + '_user', JSON.stringify(user));
    }
    return user;
  }

  static isEnvAdmin(u): Array<string> {
    const admSt = sessionStorage.getItem('[#@]' + environment.brand + '_chat');
    const deptsSt = [];
    if (admSt) {
      const admObjSt = JSON.parse(admSt);
      for (const dept of admObjSt) {
        if (dept.userId.includes(u.IdUsuario)) {
          deptsSt.push(dept.name);
        }
      }
    }
    return deptsSt;
  }

  registerUser(sbmnUser) {
    const url = this.domain + 'agencias/altaagencia/' + this.dataService.currentLang;
    sbmnUser.Sha1ClaveUsuario = sha1(sbmnUser.userpassword);
    delete sbmnUser.userpassword;
    delete sbmnUser.userpasswordrepeat;
    const body = JSON.stringify(sbmnUser);
    const headers = AuthService.buildHeader();
    return this.httpClient.post<any>(url, body, { observe: 'body', headers });
  }

  signinUser(sbmnUser) {
    const url = this.domain + 'agencias/login/' + this.dataService.currentLang;
    if (sbmnUser.password) {
      sbmnUser.Sha1Password = sha1(sbmnUser.password);
      delete sbmnUser.password;
    }
    const body = JSON.stringify(sbmnUser);
    const headers = AuthService.buildHeader();
    return this.httpClient.post<any>(url, body, { observe: 'body', headers });
  }

  resetPassword(u) {
    const url =
      this.domain +
      'agencias/recuperarpassword/' +
      u.username +
      '/' +
      u.useremail +
      '/' +
      this.dataService.currentLang;
    const headers = AuthService.buildHeader();
    return this.httpClient.get<any>(url, { observe: 'body', headers });
  }

  changePassword(u) {
    const url = this.domain + 'agencias/reseteopassword/' + this.dataService.currentLang;
    const body = JSON.stringify(u);
    const headers = AuthService.buildHeader();
    return this.httpClient.put<any>(url, body, { observe: 'body', headers });
  }

  setUser(u) {
    sessionStorage.setItem('[#@]' + environment.brand + '_user', JSON.stringify(u));
    if (this.socketService.socketOpened) this.socketService.socket.connect();
  }

  setAdmins(adm) {
    sessionStorage.setItem('[#@]' + environment.brand + '_chat', adm);
  }

  requestAdmins() {
    const url = this.domain + 'cms/chatusers';
    const headers = AuthService.buildHeader();
    return this.httpClient.get<any>(url, { observe: 'body', headers });
  }

  logout() {
    if (this.socketService.socketOpened)
      this.socketService.disconnectService().then(() => console.log('Socket disconnected'));
    if (this.socketService.socketOpened) this.socketService.closePing();
    for (const itemData in sessionStorage) {
      if (sessionStorage.hasOwnProperty(itemData) && itemData.match(/^\[#@]\w+_/g)) {
        sessionStorage.removeItem(itemData);
      }
    }
    this.storage.clear().subscribe();
    return true;
  }

  isLogged() {
    return sessionStorage.getItem('[#@]' + environment.brand + '_user') !== null;
  }
}
