import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../../environments/environment';
import { ParticipantResponse } from '../../protected/common/chat/core/participant-response';
import { ActiveConversation } from '../../protected/common/chat/core/conversation-active';
import { Message } from '../../protected/common/chat/core/message';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ChatAdapter } from '../../protected/common/chat/core/chat-adapter';
import { User, ChatUser } from '../../protected/common/chat/core/user';
import { IChatParticipant } from '../../protected/common/chat/core/chat-participant';

@Injectable({
  providedIn: 'root',
})
export class SocketService extends ChatAdapter {
  public socket: Socket;
  private SocketIoConfig = { url: environment.chat.socket, options: {} };
  public usersList: { friendsList: Array<ParticipantResponse>; activeChats: ActiveConversation[] };
  public isAdmin: boolean;
  public displayName: string;
  public avatar: string;
  public socketId: string;
  public bfyId: number;
  public adminVChat: number;
  public intervalControl: any;
  pingNumber = 0;
  public pingTimeout: any;
  public socketOpened: boolean;

  constructor(private httpClient: HttpClient) {
    super();
    this.socketOpened = false;
    if (environment.chat.socket != null) {
      this.socket = new Socket(this.SocketIoConfig);
      this.socketOpened = true;
    }
    const chatAdm = sessionStorage.getItem('[#@]' + environment.brand + '_chat');
    const chatAdmObj = chatAdm ? JSON.parse(chatAdm) : [];
    const adminDeptIds = chatAdmObj.filter(d => d.name === 'ADMINISTRACIÓN');
    if (adminDeptIds[0] && adminDeptIds[0].userId && adminDeptIds[0].userId[0]) {
      this.adminVChat = adminDeptIds[0].userId[0];
    }
  }

  /** SOCKETS functions */

  async disconnectService() {
    if (this.socketOpened) this.socket.disconnect();
  }

  // to connect the user and sends the User to the socket connection
  public joinRoom(chatUser): ChatUser {
    let isAdminValue = false;
    const isAdm = sessionStorage.getItem('[#@]' + environment.brand + '_chat');

    if (isAdm) {
      // if admin check all the admins IDs
      const admObjSt = JSON.parse(isAdm);
      for (const dept of admObjSt) {
        if (dept.userId.includes(chatUser.IdUsuario)) {
          isAdminValue = true;
          break;
        }
      }
    }

    const user = {
      bfyId: chatUser.IdUsuario,
      displayName: chatUser.NombreUsuario,
      agency: chatUser.NombreAgencia,
      isAdmin: isAdminValue,
      email: chatUser.EmailUsuario,
      avatar: chatUser.UrlLogoAgencia
        ? chatUser.UrlLogoAgencia
        : environment.domain + '/assets/img/logos/B_logo.svg',
    };
    chatUser.isAdmin = user.isAdmin;

    if (this.socketOpened) this.socket.emit('join', user);

    return user;
  }

  sendMessage(message: Message): void {
    if (this.socketOpened) this.socket.emit('sendMessage', message);
  }

  /** HTTP calls to backend */

  // socket or bfyid, the back will know bec one is Nan and the other
  public getUserDBdata(userSocket: any) {
    const url = `${environment.chat.api}/user/${userSocket}`;
    return this.httpClient.get<any>(url);
  }

  getConvWithUser(chatId: string) {
    const url = `${environment.chat.api}/chat/${chatId}`;
    return this.httpClient.get<any>(url);
  }

  searchConvs(extUser: User, department: string) {
    const url = `${environment.chat.api}/chat/${department}/${extUser.displayName}`;
    return this.httpClient.get<any>(url);
  }

  // if admin I add the departments soI get all the conversations active with the departments I belong
  listUsersInChat(departments: string[] | undefined) {
    // List connected users to show in the friends list
    const url = `${environment.chat.api}/user/listUsersInChat`;

    const body = JSON.stringify({
      isAdmin: this.isAdmin,
      displayName: this.displayName,
      userId: this.socketId,
      avatar: this.avatar,
      dep: departments,
      bfyId: this.bfyId,
    });

    const headers = new HttpHeaders()
      .set('Accept', 'application/json, text/plain')
      .append('Content-Type', 'application/json;charset=UTF-8');

    return this.httpClient.post<any>(url, body, { observe: 'body', headers });
  }

  getMessageHistory(otherUserId: IChatParticipant) {
    // socket id
    // API call to your NodeJS application that would go to the database
    const url = `${environment.chat.api}/chat/history`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json, text/plain')
      .append('Content-Type', 'application/json;charset=UTF-8');
    const body = JSON.stringify({
      otherUserId: otherUserId, // boomerangfly ID not socket
      userId: this.socketId, // my socket ID
    });
    return this.httpClient.post<any>(url, body, { headers });
  }

  /** END of HTTP calls */

  /** PING functions to reconnect -- check connection */
  initPing() {
    this.intervalControl = setInterval(() => {
      this.sendPing();
    }, 5000);

    // if (this.isAdmin) {
    //   this.pingTimeout = setTimeout(() => {
    //     console.log('DESCONECTANDO  ')
    //     this.socket.disconnect();
    //   }, 1000);
    // } else {
    //   this.pingTimeout = setTimeout(() => {
    //     console.log('DESCONECTANDO  ')
    //     this.socket.disconnect();
    //   }, 10000);
    // }
  }

  sendPing() {
    if (this.socketOpened) this.socket.emit('eco');
    // const d = new Date();
    // console.log('eco', d.toString());
  }

  closePing() {
    clearInterval(this.intervalControl);
    // const d = new Date();
    // console.log('close eco', d.toString());
    // console.log('close eco');
  }

  /** UTILS FUNCTIONS  */
  public getWorkingTime() {
    if (environment.production) {
      const currentTime = new Date();
      const noWeekend = !(currentTime.getDay() % 6 === 0);
      const h = currentTime.getHours();
      return ((h >= 10 && h < 14) || (h >= 15 && h < 19)) && noWeekend;
    } else {
      return true;
    }
  }

  setUser(isAdmin: boolean, userName: string, avatar: string, socketId: string, bfyId: number) {
    this.displayName = userName;
    this.isAdmin = isAdmin;
    this.avatar = avatar;
    this.socketId = socketId;
    this.bfyId = bfyId;
  }
}
