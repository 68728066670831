import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  openedDialog: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  openDialog(data) {
    this.openedDialog.emit(data);
  }

  closeDialog() {
    this.openedDialog.emit(null);
  }
}
