import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private renderer: Renderer2;
  public gW: any;
  public s: number;
  public sdown: boolean;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.gW = this.renderer.selectRootElement(window);
    this.renderer.listen('window', 'scroll', event => {
      this.sdown = event.target.scrollingElement.scrollTop > this.s;
      this.s = event.target.scrollingElement.scrollTop;
      // console.log('s:' + this._sdown, this._s);
    });
  }
  /*
  resetScroll() {
    this.s = 0;
  }*/

  scroll2Top(top: number = 0, speed: number = 200) {
    if (this.s <= top) {
      return false;
    }
    this.gW.scrollTo(0, this.s - speed);
    setTimeout(() => {
      this.scroll2Top(top, speed);
    }, 1);
  }
}
