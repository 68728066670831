import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Component({
  selector: 'sbmn-error-dialog',
  templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent implements OnInit {
  errordialogContent: any;

  constructor(private errorHandlerService: ErrorHandlerService) {}

  ngOnInit(): void {
    this.errorHandlerService.openedDialog.subscribe({
      next: e => {
        if (e && e.error) {
          if (typeof e.error === 'string' || e.error instanceof String) {
            e.error = [e.error];
          }
          if (
            e.error.Message &&
            (typeof e.error.Message === 'string' || e.error.Message instanceof String)
          ) {
            e.error = [e.error.Message];
          }
        }
        if (e && e.message && (typeof e.message === 'string' || e.message instanceof String)) {
          e.message = [e.message];
        }
        console.log(e);
        this.errordialogContent = e;
      },
    });
  }

  resetError() {
    this.errorHandlerService.closeDialog();
  }
}
