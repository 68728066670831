import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CopyService {
  constructor() {}

  copying(str, type) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/' + type, str);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    return document.execCommand('copy');
  }
}
