import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Availability, Prebook, AvailabilityFilters } from '../interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FlightsResultsService {
  constructor(private storage: StorageMap) {
    this.availability = {};
  }

  private availability: any;
  private availabilitySchemes: any;
  private availabilitySegments: any;
  private availabilityCalendar: any;
  private availabilityFilters: any;
  private availabilityId: number;

  /*.................................VOUCHER...................*/

  saveTPVVoucher(voucher) {
    this.storage.set('tpvVoucher', voucher).subscribe(() => {});
  }

  saveInespayVoucher(voucher) {
    return this.storage.set('tpvVoucher', voucher);
  }

  getTPVVoucher() {
    return this.storage.get('tpvVoucher');
  }

  cleanTPVVoucher() {
    return this.storage.delete('tpvVoucher');
  }

  /*.................................SEARCH...................*/

  saveSearch(search) {
    this.storage.set('search', search).subscribe(() => {});
  }

  getSearch() {
    return this.storage.get('search');
  }

  cleanSearch() {
    return this.storage.delete('search');
  }

  /*.................................PASSENGERS...................*/

  savePsg(passengers) {
    this.storage.set('psg', passengers).subscribe(() => {});
  }

  getPsg() {
    return this.storage.get('psg');
  }

  cleanPsg() {
    return this.storage.delete('psg');
  }

  /*.................................PRE-bookingStatus...................*/

  savePreReservationData(prebook: Prebook) {
    return this.storage.set('prebook', prebook);
  }

  getPreReservationData() {
    return this.storage.get('prebook');
  }

  delPreReservationData() {
    return this.storage.delete('prebook');
  }

  /*.................................AVAILABILITY...................*/

  getStoredAvailability() {
    return this.storage.get('availability');
  }

  cleanStoredAvailability() {
    return this.storage.delete('availability');
  }

  getAvailability(t: number) {
    let av: any;
    switch (t) {
      case 1:
        av = this.availabilitySchemes;
        break;
      case 2:
        av = this.availabilitySegments;
        break;
      case 3:
        av = this.availabilityCalendar;
        break;
      default:
        av = this.availability;
        break;
    }
    return av;
  }

  saveAvailability(avail: any) {
    this.availability = avail;
    // console.log(_avail);
    this.setAvailabilityId(this.availability.IDPeticionWSMotor);
    this.setStoreLCAdvice();
    this.customizeAvailability();
    this.delPreReservationData().subscribe(() => {});
  }

  setStoreLCAdvice() {
    if (this.availability.AvisoReservaLowCost) {
      this.storage.set('lowcostText', this.availability.AvisoReservaLowCost[0]).subscribe(() => {});
    }
  }

  getStoreLCAdvice() {
    return this.storage.get('lowcostText');
  }

  setAvailabilityId(id) {
    this.availabilityId = id;
    sessionStorage.setItem('[#@]' + environment.brand + '_buavid', id.toString());
  }

  getAvailabilityId() {
    return this.availabilityId
      ? this.availabilityId
      : +sessionStorage.getItem('[#@]' + environment.brand + '_buavid');
  }

  customizeAvailability() {
    const newSchemes: any = [];
    if (this.availability.EsquemasPrecio) {
      for (const ep of Object.keys(this.availability.EsquemasPrecio)) {
        const scheme = Object.assign({}, this.availability.EsquemasPrecio[ep]);
        scheme.TrayectosLength = 0;
        scheme.Segmentos = [];
        scheme.TarifaNDC = null;
        if (this.availability.EsquemasPrecio[ep].CodTarifa == 'NDC')
          scheme.TarifaNDC = this.availability.EsquemasPrecio[ep].Combinaciones[0].TarifaNDC;
        delete scheme.Combinaciones;
        for (const comb of this.availability.EsquemasPrecio[ep].Combinaciones) {
          let segmentNum = 0;
          for (const combSeg of comb.CombinacionesSegmentos) {
            segmentNum++;
            // -------------TStopover + baggage + seats + class
            const cSegTray = combSeg.CombinacionSegmentosTrayectos;
            let baggage: number = null;
            let seats = 0;
            let journeyClass = '';
            let isCupo = false;
            for (const cst of cSegTray) {
              if (!baggage || cst.EquipajeADT.CantidadPiezas < baggage) {
                baggage = cst.EquipajeADT.CantidadPiezas;
              }
              if (seats === 0 || cst.AsientosDisponibles < seats) {
                seats = cst.AsientosDisponibles;
              }
              if (journeyClass.indexOf(cst.Clase) === -1) {
                journeyClass += cst.Clase;
              }
              if (cst.EsCupo) {
                isCupo = true;
              }
            }
            const custom = {
              baggageType: cSegTray[0].EquipajeADT.TipoEquipaje,
              baggageUnit: cSegTray[0].EquipajeADT.UnidadMedida,
              parsedLuggage: baggage,
              parsedSeats: seats,
              parsedClass: journeyClass,
              isCupo,
            };
            for (const stidx of Object.keys(
              this.availability.Segmentos[combSeg.IdSegmento].Trayectos,
            )) {
              const st = this.availability.Segmentos[combSeg.IdSegmento].Trayectos[stidx];
              const extraData = cSegTray.filter(segT => segT.IdTrayecto === st.IDTrayecto);
              st.Cabina = extraData[0].Cabina;
              st.CabinaMaestra = extraData[0].CabinaMaestra;
              st.TarifaCodigo = extraData[0].TarifaCodigo;
              if (st.ParadasTecnicas) {
                this.availability.Segmentos[combSeg.IdSegmento].TStopover = true;
              }
            }
            // -------------TStopover + baggage + seats + class

            const segmentName = 'Segmento' + segmentNum;
            if (!scheme[segmentName]) {
              scheme[segmentName] = {};
            }
            if (!scheme[segmentName][combSeg.IdSegmento]) {
              scheme[segmentName][combSeg.IdSegmento] = {
                customData: custom,
                Combinatoria: [],
                AerolineaValidadora: comb.AerolineaValidadora,
                IdSegmento: combSeg.IdSegmento,
                CombinacionSegmentosTrayectos: combSeg.CombinacionSegmentosTrayectos,
              };
            }
            for (const combSegComb of comb.CombinacionesSegmentos) {
              if (combSegComb !== combSeg) {
                const combinatoria = {
                  IdSegmento: combSegComb.IdSegmento,
                  // 'AerolineaValidadora':comb.AerolineaValidadora,
                };
                scheme[segmentName][combSeg.IdSegmento].Combinatoria.push(combinatoria);
              }
            }
          }
        }
        let segment2Num = 0;
        for (const comb2Seg of this.availability.EsquemasPrecio[ep].Combinaciones[0]
          .CombinacionesSegmentos) {
          segment2Num++;
          const segment2Name = 'Segmento' + segment2Num;
          const newScheme = [];
          for (const schemeIndx of Object.keys(scheme[segment2Name])) {
            const newSche = scheme[segment2Name][schemeIndx];
            newScheme.push(newSche);
            scheme.TrayectosLength++;
          }
          newScheme.sort((a, b) => {
            const segmentoA: number = +this.availability.Segmentos[a.IdSegmento].HoraSalida.replace(
              ':',
              '',
            );
            const segmentoB: number = +this.availability.Segmentos[b.IdSegmento].HoraSalida.replace(
              ':',
              '',
            );
            if (segmentoA > segmentoB) {
              return 1;
            }
            if (segmentoA < segmentoB) {
              return -1;
            }
            return 0;
          });
          // scheme[segment2Name] = newScheme;
          delete scheme[segment2Name];
          scheme.Segmentos.push(newScheme);
        }
        newSchemes.push(scheme);
        // console.log('Scheme: ',scheme);
      }
    }
    this.availabilitySchemes = newSchemes;
    this.availabilitySegments = this.availability.Segmentos;
    this.availabilityFilters = this.availability.Filtros
      ? Object.assign({}, this.availability.Filtros)
      : null;
    // console.log(this._availability_schemes);
    // console.log(this._availability_segments);
    if (this.availability.Calendario) {
      // console.log(this._availability.Calendario);
      const combinacionesArray = Object.entries(this.availability.Calendario.Combinaciones);
      for (const c of combinacionesArray) {
        c[1] = Object.entries(c[1]);
      }
      this.availabilityCalendar = Object.assign({}, this.availability.Calendario);
      this.availabilityCalendar.Combinaciones = combinacionesArray;
      // console.log(combinacionesArray);
    }
    this.setStoredAvailability();
  }

  setStoredAvailability() {
    this.cleanStoredAvailability().subscribe(() => {});
    const av: Availability = {
      Segmentos: this.availabilitySegments,
      EsquemasPrecio: this.availabilitySchemes,
      Filtros: this.availabilityFilters,
      IDPeticionWSMotor: this.availability.IDPeticionWSMotor,
      Calendario: this.availabilityCalendar,
    };
    this.storage.set('availability', av).subscribe(() => {});
  }

  setSaveStoredCalendarAvailability(calendar) {
    this.availabilityCalendar = calendar.Calendario;
    this.storage.delete('calendar').subscribe(() => {
      const setCalendar = {
        Calendario: this.availabilityCalendar,
        IDPeticionWSMotor: calendar.IDPeticionWSMotor,
      };
      this.storage.set('calendar', setCalendar).subscribe(c => {
        return c;
      });
    });
  }

  /*.................................FILTERS...................*/

  getFilters() {
    return this.availabilityFilters;
  }

  setFilters(filters) {
    this.availabilityFilters = filters;
  }

  setStoredFilters(filters: AvailabilityFilters) {
    this.storage.set('avFilters', filters).subscribe(() => {});
  }

  cleanStoredFilters() {
    return this.storage.delete('avFilters');
  }

  getStoredFilters() {
    return this.storage.get('avFilters');
  }
}
