import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DataService } from './data.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfService {
  confDefaultCurrency: string;

  constructor(
    private translate: TranslateService,
    private dataService: DataService,
    private storage: StorageMap,
  ) {
    this.setLanguage(this.dataService.currentLang);
    this.setCurrency('EUR');
  }

  setCurrency(curr: string) {
    this.storage.set('moneda', curr).subscribe({ next: () => {} });
    this.confDefaultCurrency = curr;
  }

  formatStringCurrency(st: string) {
    return Number(st.replace('.', '').replace(',', '.'));
  }

  getCurrency() {
    return this.storage.get<string>('moneda', { type: 'string' });
  }

  setLanguage(lang: string, reload: boolean = false) {
    this.translate.addLangs([lang]);
    this.translate.setDefaultLang(lang);
    if (reload) {
      window.location.reload(); // TOO dirty workaround to refresh pipes
    }
  }

  saveMsgConfig(value) {
    console.log('Guardar msg', value);
    return this.storage.set('messagesEnable', value).subscribe({ next: () => {} });
  }

  getMsgConfig() {
    return this.storage.get<boolean>('messagesEnable', { type: 'boolean' });
  }

  getAppSettings() {
    // TODO: ACTIVAR O DESACTIVAR INESPAY => formasPagoReservaAereo: [{Id: "TPV", Texto: "Pago por tarjeta de crédito"}, {Id: "SAL", Texto: "Saldo Agencia"}, {Id: "INE", Texto: "Pago por Transferencia Online"}]
    return {
      activateUnifiedMessages: environment.myMessages,
      activateManagerUsers: environment.allowedManagerUsers,
      activateVideo: environment.allowedVideo,
      messagesReserveDataDepartments: [27, 29, 16, 2, 1],
      formasPagoReservaAereo: [
        { Id: 'TPV', Texto: 'Pago por tarjeta de crédito' },
        { Id: 'SAL', Texto: 'Saldo Agencia' },
        { Id: 'INE', Texto: 'Pago por Transferencia Online' },
      ],
    };
  }
}
