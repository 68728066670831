import { Observable } from 'rxjs';
import { Message } from './message';
import { ParticipantResponse } from './participant-response';
import { ActiveConversation } from './conversation-active';

export abstract class ChatAdapter {
  // ### Abstract adapter methods ###

  public abstract listUsersInChat(
    departments: string[] | undefined,
  ): Observable<{ friendsList: ParticipantResponse[]; activeChats: ActiveConversation[] }>;

  public abstract getMessageHistory(destinataryId: any): Observable<Message[]>;

  public abstract sendMessage(message: Message): void;
}
