<section *ngIf="errordialogContent" class="stopper-modal Berror appear">
  <div class="stopper-modal__scroll expand-modal">
    <div class="stopper-modal__scroll__container">
      <span (click)="resetError()" class="ko"></span>
      <div class="stopper-modal__scroll__container__title warning"></div>
      <div class="stopper-modal__scroll__container__content reduced">
        <ng-container *ngIf="errordialogContent.MensajesError">
          <div *ngFor="let error of errordialogContent.MensajesError" [innerHTML]="error"></div>
        </ng-container>
        <ng-container *ngIf="errordialogContent.error?.MensajesError">
          <div
            *ngFor="let error of errordialogContent.error.MensajesError"
            [innerHTML]="error"
          ></div>
        </ng-container>
        <ng-container *ngIf="errordialogContent.error?.length">
          <div *ngFor="let error of errordialogContent.error" [innerHTML]="error"></div>
        </ng-container>
        <ng-container *ngIf="!errordialogContent.error.MensajesError">
          <div *ngFor="let error of errordialogContent.message" [innerHTML]="error"></div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
