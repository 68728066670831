import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import { ConfService } from '../services';
import { environment } from '../../../environments/environment';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');
registerLocaleData(localePt, 'pt');

@NgModule({
  providers: [
    ConfService,
    {
      provide: LOCALE_ID,
      useFactory: () => {
        return 'es';
        // // const localLang = localStorage.getItem('[#@]' + environment.brand + '_idioma');
        // // return (localLang) ? localLang : 'es';
        // this.ConfService.getLanguage().subscribe((l) => {
        //   return l;
        // });
      },
    },
  ],
})
export class LanguageModule {}
