import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  isPhone(control: UntypedFormControl): { [s: string]: boolean } {
    const numRExp = /^\+?[\s\d \-\(\)]+$/;
    if (!numRExp.test(control.value) && control.value !== '') {
      return { notPhone: true };
    }
    return null;
  }

  mailFormat(separator?: string): ValidatorFn {
    return (control: UntypedFormControl): { [s: string]: boolean } => {
      if (!control.value) {
        return null;
      }

      const mex =
        /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

      const mailIsInvalid = (value: string): boolean =>
        (value !== '' && (value.length <= 5 || !mex.test(value))) || value === ''  ? true : false;

      if (separator) {
        const mails = control.value.split(separator);

        if (mails.some(mailIsInvalid)) {
          return { incorrectMailFormat: true };
        }
      } else if (mailIsInvalid(control.value)) {
        return { incorrectMailFormat: true };
      }

      return null;
    };
  }

  idNumber(idType: string, resident: boolean = false) {
    return (control: UntypedFormControl): { [key: string]: any } => {
      let hasMatch = null;
      const v = control.value;
      const type = control.parent?.controls[idType].value;
      const paxType = control.parent?.controls['TipoPasajero'].value;
      const residentType =
        resident && control.parent?.controls['ResidenteDocumentoTipo']
          ? control.parent?.controls['ResidenteDocumentoTipo'].value
          : '5';
      if (paxType !== 'ADT' && residentType == '5') return null;
      if (type && v) {
        switch (type) {
          case 'DNI':
          case 'I':
            if (!resident) {
              hasMatch = v.match(/^[0-9A-Z]{5,12}$/i);
            } else {
              const dnimatch = v.match(/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i);
              if (dnimatch) {
                const posDni = +dnimatch[0].substring(0, 8) % 23;
                hasMatch =
                  'TRWAGMYFPDXBNJZSQVHLCKET'.charAt(posDni) === dnimatch[0].charAt(8).toUpperCase();
              }
            }
            break;
          case 'NIE':
          case 'IP':
            const niematch = v.match(/^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i);
            if (niematch) {
              let n;
              switch (niematch[0].charAt(0)) {
                case 'X':
                  n = 0;
                  break;
                case 'Y':
                  n = 1;
                  break;
                case 'Z':
                  n = 2;
                  break;
              }
              const posNie = +(n + niematch[0].substring(1, 8)) % 23;
              hasMatch =
                'TRWAGMYFPDXBNJZSQVHLCKET'.charAt(posNie) === niematch[0].charAt(8).toUpperCase();
            }
            break;
          case 'PAS':
          case 'P':
            hasMatch = v.match(/^[a-z0-9]{7,11}$/i);
            break;
        }
      }
      // console.log(resident, v + '  ' + type);
      if (!hasMatch) {
        return { notValidId: true };
      }
      return null;
    };
  }

  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const password = group.controls[passwordKey];
      const confirmPassword = group.controls[confirmPasswordKey];
      if (password.value !== confirmPassword.value) {
        return { mismatchedPasswords: true };
      }
    };
  }

  isAirport(control: UntypedFormControl): { [s: string]: boolean } {
    const numRExp = /^[A-Z]{3}\s·\s.+$/;
    if (!numRExp.test(control.value) && control.value !== '') {
      return { notAirport: true };
    }
    return null;
  }

  validCC(control: UntypedFormControl): { [s: string]: boolean } {
    if (/[^0-9-\s]+/.test(control.value)) {
      return { notValid: true };
    }
    // The Luhn Algorithm. It's so pretty.
    let nCheck = 0;
    let nDigit = 0;
    let bEven = false;
    const nvalue = control.value.replace(/\D/g, '');
    for (let n = nvalue.length - 1; n >= 0; n--) {
      const cDigit = nvalue.charAt(n);
      nDigit = parseInt(cDigit, 10);
      if (bEven) {
        // TODO check
        nDigit = nDigit * 2;
        if (nDigit > 9) {
          nDigit -= 9;
        }
      }
      nCheck += nDigit;
      bEven = !bEven;
    }
    return nCheck % 10 > 0 ? { notValid: true } : null;
  }
}
