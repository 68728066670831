<div class="lang-selector" *ngIf="langSelectorLangs && langSelectorLangs.length > 1">
  <mat-form-field>
    <div id="langTrigger">{{ langSelectorSelected }}</div>
    <mat-select
      [value]="langSelectorSelected"
      required
      disableRipple
      (selectionChange)="changeLang($event)"
    >
      <mat-option *ngFor="let l of langSelectorLangs" [value]="l.Id">{{ l.Texto }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
