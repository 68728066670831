import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private translate: TranslateService,
    private pageTitle: Title,
    private pageMeta: Meta,
  ) {}

  setMetaDefaults() {
    if (environment.googleSiteVerification) {
      this.pageMeta.updateTag({
        name: 'google-site-verification',
        content: environment.googleSiteVerification,
      });
    }
    if (!environment.production) {
      const metaCache = [
        ['Cache-Control', 'no-cache, no-store, must-revalidate'],
        ['Pragma', 'no-cache'],
        ['Expires', '0'],
      ];
      for (const meta of metaCache) {
        this.pageMeta.updateTag({
          'http-equiv': meta[0],
          content: meta[1],
        });
      }
    }
  }

  setTags() {
    this.translate.get('comun').subscribe((s: any) => {
      if (typeof s !== 'string') {
        this.pageTitle.setTitle(s.title);
        this.pageMeta.updateTag({
          name: 'description',
          content: s.description,
        });
        this.pageMeta.updateTag({
          name: 'keywords',
          content: s.keywords,
        });
      }
    });
  }
}
