import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  shareData: EventEmitter<boolean> = new EventEmitter();
  langList: EventEmitter<boolean> = new EventEmitter();
  currentLang: string;
  savedLang: string;
  private readonly sharedData: any;
  private readonly domain: string;

  constructor(private httpClient: HttpClient) {
    this.domain = environment.apidomain;
    this.savedLang = sessionStorage.getItem('[#@]' + environment.brand + '_defaultLang');
    this.currentLang = this.savedLang ? this.savedLang : environment.defaultLang;
    this.getLangs();
    this.sharedData = {
      balance: null,
      unreadMessages: null,
    };
  }

  static getToken() {
    const user = JSON.parse(sessionStorage.getItem('[#@]' + environment.brand + '_user'));
    return 'BEARER ' + user.Token;
  }

  static buildHeader(type: number) {
    switch (type) {
      case 0:
        return new HttpHeaders().set('Accept', 'application/json');
      case 1:
        return new HttpHeaders()
          .set('Accept', 'application/json')
          .append('Authorization', DataService.getToken());
      case 2:
        return new HttpHeaders()
          .set('Accept', 'application/json, text/plain')
          .append('Content-Type', 'application/json;charset=UTF-8');
      case 3:
        return new HttpHeaders()
          .set('Accept', 'application/json, text/plain')
          .append('Content-Type', 'application/json;charset=UTF-8')
          .append('Authorization', DataService.getToken());
      case 4:
        return new HttpHeaders()
          .set(
            'Accept',
            'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
          )
          .append('Content-Type', 'application/x-www-form-urlencoded');
      case 5:
        return new HttpHeaders()
          .set('responseType', 'text')
          .append('Content-Type', 'text/html')
          .append('Accept', 'text/html');
      case 6:
        return new HttpHeaders().set('responseType', 'blob').append('Accept', 'application/pdf');
    }
  }

  /*  getDomain() {
      return this._domain;
    }*/

  getLangs() {
    this.getNoAuth('agencias/idiomas').subscribe(
      ls => {
        sessionStorage.setItem('[#@]' + environment.brand + '_langs', JSON.stringify(ls));
        this.langList.emit(ls);
      },
      error => {
        console.log(error);
      },
    );
  }

  exportTkn() {
    return DataService.getToken();
  }

  getExternalData(url: string) {
    const headers = DataService.buildHeader(0);
    return this.httpClient.get<any>(url, { observe: 'body', headers });
  }

  getHtmlData(path: string) {
    const url = this.domain + path + '/' + this.currentLang;
    const headers = DataService.buildHeader(5);
    return this.httpClient.get<string>(url, {
      observe: 'body',
      headers,
      responseType: 'text' as 'json',
    });
  }

  getPdfData(path: string) {
    const url = this.domain + path + '/' + this.currentLang;
    const headers = DataService.buildHeader(6);
    return this.httpClient.get<string>(url, {
      observe: 'body',
      headers,
      responseType: 'blob' as 'json',
    });
  }

  getData(path: string, extra: string = '') {
    const url =
      extra === 'no-lang'
        ? this.domain + path
        : this.domain + path + '/' + this.currentLang + extra;
    const headers = DataService.buildHeader(1);
    return this.httpClient.get<any>(url, { observe: 'body', headers });
  }

  postData(path: string, post: any) {
    const url = this.domain + path + '/' + this.currentLang;
    const body = JSON.stringify(post);
    const headers = DataService.buildHeader(3);
    return this.httpClient.post<any>(url, body, { observe: 'body', headers });
  }

  postDataFile(path: string, post: any) {
    const url = this.domain + path + '/' + this.currentLang;
    const headers = DataService.buildHeader(4);
    const options = { headers, responseType: 'Blob' as 'json' };
    return this.httpClient.post<any>(url, post.toString(), options);
  }

  postHtmlData(path: string, post: any) {
    const url = this.domain + path + '/' + this.currentLang;
    const body = JSON.stringify(post);
    const headers = DataService.buildHeader(3);
    const options = { headers, responseType: 'text' as 'json' };
    return this.httpClient.post<any>(url, body, options);
  }

  postDataExcel(path: string, post: any) {
    const url = this.domain + path + '/' + this.currentLang;
    const body = JSON.stringify(post);
    const headers = DataService.buildHeader(3);
    const options = { headers, responseType: 'Blob' as 'json' };
    return this.httpClient.post<any>(url, body, options);
  }

  putData(path: string, post: any) {
    const url = this.domain + path + '/' + this.currentLang;
    const body = JSON.stringify(post);
    const headers = DataService.buildHeader(3);
    return this.httpClient.put<any>(url, body, { observe: 'body', headers });
  }

  deleteData(path: string) {
    const fuUrl = this.domain + path + '/' + this.currentLang;
    const headers = DataService.buildHeader(3);
    return this.httpClient.delete<any>(fuUrl, { observe: 'body', headers });
  }

  getNoAuth(path: string) {
    const url = this.domain + path + '/' + this.currentLang;
    const headers = DataService.buildHeader(0);
    return this.httpClient.get<any>(url, { observe: 'body', headers });
  }

  postNoAuth(path: string, post: any) {
    const url = this.domain + path + '/' + this.currentLang;
    const body = JSON.stringify(post);
    const headers = DataService.buildHeader(2);
    return this.httpClient.post<any>(url, body, { observe: 'body', headers });
  }

  setSharedData() {
    this.getData('agencias/saldoactualformateado').subscribe(
      totalBalance => {
        this.sharedData.balance = totalBalance;
        this.shareData.emit(this.sharedData);
        // console.log(this._sharedData);
      },
      error => {
        console.log(error);
      },
    );
    this.getData('agencias/mensajespendientes').subscribe(
      unreadMsg => {
        // console.log(unreadMsg);
        this.sharedData.unreadMessages = unreadMsg;
        this.sharedData.unreadMessages.total_f = unreadMsg.Aereo;
        this.sharedData.unreadMessages.total = unreadMsg.Incidencias
          ? unreadMsg.Generales + unreadMsg.Incidencias
          : unreadMsg.Generales;
        this.shareData.emit(this.sharedData);
        // console.log(this._sharedData);
      },
      error => {
        console.log(error);
      },
    );
  }

  getSharedData() {
    return this.sharedData;
  }

  /*  sendEmail(path: string, post: any) {
      /!* const fu__url = this._domain + path;
       const body = JSON.stringify(post);
       const headers = this.buildHeader(3);
       return this._httpClient.post<any>(fu__url, body, { observe: 'body',headers:headers });*!/
    }*/
}
