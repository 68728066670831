import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '../services/error-handler.service';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  showNoError: any;

  constructor(public errorHandlerService: ErrorHandlerService) {
    this.showNoError = ['/agencias/login/'];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        let showError: boolean;
        for (const u of this.showNoError) {
          showError = request.url.indexOf(u) < 0;
        }
        if (showError) {
          this.errorHandlerService.openDialog(error);
        }
        return throwError(error);
      }),
    );
  }
}
