import { Component, OnInit } from '@angular/core';
import { ConfService, DataService } from '../../services';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'sbmn-lang-selector',
  templateUrl: './lang-selector.component.html',
  styles: [],
})
export class LangSelectorComponent implements OnInit {
  langSelectorLangs: Array<any>;
  langSelectorSelected: string;
  constructor(
    private confService: ConfService,
    private dataService: DataService,
  ) {}

  ngOnInit(): void {
    this.dataService.getLangs();
    this.langSelectorSelected = this.dataService.currentLang;
    this.dataService.langList.subscribe({
      next: ls => {
        this.langSelectorLangs = ls;
      },
    });
  }

  changeLang(e) {
    const l = e.value.toLowerCase();
    this.confService.setLanguage(l);
    this.langSelectorSelected = l;
    this.dataService.currentLang = l;
    sessionStorage.setItem('[#@]' + environment.brand + '_defaultLang', l);
    // this.findLang();
  }

  // findLang() {
  //   this.confService.getLanguage().subscribe( (l: string) => {
  //     this.langSelectorSelected = l;
  //   });
  // }
}
