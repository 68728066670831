import { Component } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { Event, NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Location } from '@angular/common';
import { FlightsResultsService, SeoService } from './shared/services';

@Component({
  selector: 'sbmn-root',
  templateUrl: './sbmn.component.html',
})
export class SbmnComponent {
  title;
  constructor(
    private router: Router,
    private location: Location,
    private seoService: SeoService,
    private flightsResultsService: FlightsResultsService,
    private ngGTM: Angulartics2GoogleTagManager,
  ) {
    ngGTM.startTracking();
    seoService.setMetaDefaults();
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        seoService.setTags();
        if (event.url === '/' + environment.brand + '/flights') {
          // clean search engine
          this.flightsResultsService.cleanSearch().subscribe();
          this.flightsResultsService.cleanPsg().subscribe();
        }
      }
    });
  }
}
