import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LangSelectorComponent } from './lang-selector.component';
import { FormsMatModule } from '../../modules/forms-mat.module';

@NgModule({
  declarations: [LangSelectorComponent],
  imports: [CommonModule, FormsMatModule],
  exports: [LangSelectorComponent],
})
export class LangSelectorModule {}
